import {createSlice} from '@reduxjs/toolkit';

const userSlice = createSlice({
    name:"user",
    initialState:{
        userData:    null,
        menuData:    null,
        siteRedirects:    null,
        isSignedIn:  false,
    },
    reducers:{
        setSignedIn: (state, action) => {
            state.isSignedIn = action.payload;
        },
        setUserData: (state, action) => {
            state.userData = action.payload;
        },
        setMenuData: (state, action) => {
            state.menuData = action.payload;
        },
        setSiteRedirects: (state, action) => {
            state.siteRedirects = action.payload;
        },
    },
});

export const{
    setSignedIn,
    setUserData,
    setMenuData,
    setSiteRedirects
} = userSlice.actions;

export const selectSignedIn              = (state) => state.user.isSignedIn;
export const selectUserData              = (state) => state.user.userData;
export const selectMenuData              = (state) => state.user.menuData;
export const selectSiteRedirects         = (state) => state.user.siteRedirects;
export default userSlice.reducer;