import { ThemeProvider } from "next-themes";
import 'react-toastify/dist/ReactToastify.css';
import '../assets/css/style.css'
import { useEffect, useRef, useState } from "react";
import { useRouter } from "next/router";
import SpinnerLoading from "../components/loading";
import {Provider, useSelector} from "react-redux"
import store from "../redux/store"
import ErrorBoundary from "../components/ErrorBoundary";
import FiveHundred from "./500";
import { ToastContainer, toast } from 'react-toastify';
import Script from "next/script";
import customScript from '../utils/cutomScript.js'
import {ReCaptchaProvider} from "next-recaptcha-v3";



function MyApp({ Component, pageProps }) {
    const router = useRouter();
    const [loading, setLoading] = useState(true);
    const [recaptchaLoaded, setRecaptchaLoaded] = useState(false);

    useEffect(() => {
        const recaptchaTimer = setTimeout(() => {
            setRecaptchaLoaded(true); // Enable reCAPTCHA after 10 seconds
        }, 10000);

        if (typeof window !== "undefined") {
            const siteRedirect = localStorage.getItem('site_redirect') || '[]';
            const redirectCollection = JSON.parse(siteRedirect);

            const redirect = redirectCollection.find(item => router.asPath === item.source);
            if (redirect) {
                router.push(redirect.destination);
            }
        }
        const handleStart = () => {
            setLoading(true);
        };
        const handleComplete = () => {
            setTimeout(() => {
                setLoading(false);
            }, 5000)
        };

        router.events.on("routeChangeStart", handleStart);
        router.events.on("routeChangeComplete", handleComplete);
        router.events.on("routeChangeError", handleComplete);
        setLoading(false);
        return () => {
            router.events.off("routeChangeStart", handleStart);
            router.events.off("routeChangeComplete", handleComplete);
            router.events.off("routeChangeError", handleComplete);
            clearTimeout(recaptchaTimer);
        };
    }, [router]);
    return (
        <Provider store={store}>
            <ErrorBoundary FallbackComponent={<FiveHundred />}>
                <ThemeProvider attribute="class">
                    {loading && <SpinnerLoading />}
                    <ToastContainer
                        position="top-right"
                        autoClose={5000}
                        hideProgressBar={false}
                        newestOnTop={false}
                        closeOnClick
                        rtl={false}
                        pauseOnFocusLoss
                        draggable
                        pauseOnHover
                        theme="light"
                    />
                    {recaptchaLoaded && <ReCaptchaProvider reCaptchaKey="6LfSkcUpAAAAAN_fDZAHo_4pL6pzaxzdHdu5VYXU" />}
                    <Component {...pageProps} />
                    {/* </ReCaptchaProvider> */}
                </ThemeProvider>
            </ErrorBoundary>
        </Provider>
    );
}

export default MyApp;
